

export interface Modal<T = any> {
    open?: boolean;
    modal?: string;
    data?: T;
    result?: any;
}


export const CONFIRMATION_MODAL = "CONFIRMATION_MODAL";

export interface ModalState {
    [CONFIRMATION_MODAL]: Modal<{body: string, confirmAction: () => void}>
}