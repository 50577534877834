import React from 'react';
import {FormGroup} from "reactstrap";

type Props = {
    label: string;
    value:string;
    placeholder?: string;
    className?: string;
    onChange?: (value: any) => void
}


const InputGroupComponent = ({label, placeholder, className, onChange, value}: Props) => {
    return (
        <FormGroup className={className ?? ""}>
            <label>{label}</label>
            <input type="text" placeholder={placeholder ?? ""} value={value} className={"form-control"} onChange={ onChange ? (evt) => onChange(evt.target.value): undefined}/>
        </FormGroup>
    );
};

export default InputGroupComponent;
