import {runCS, sbxCoreService} from '../Network';
import env from '../Environment';
import {RecoverPasswordParams} from "../Redux/Auth/Types";

export const login = (login: string, password: string): any => {
  return runCS(env.CLOUD_SCRIPTS.LOGIN, {login, password});
}

export function recoverPassword (params: RecoverPasswordParams) {
  return sbxCoreService.requestChangePassword(
    params.user_id,
    params.code,
    params.password
  );
}

export function changePasswordService(currentPassword:string, newPassword:string) {
  // return sbxCoreService.changePassword(password);
  return sbxCoreService.changeMyPassword(currentPassword,newPassword)
}
