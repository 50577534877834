import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Modal} from "reactstrap";
import {modalActions} from "../../../../Redux/Modal/Slice";
import {modalReducer} from "../../../../Redux/Selectors";
import {CONFIRMATION_MODAL} from "../../../../Redux/Modal/Types";


const ConfirmationModal = () => {

    const {CONFIRMATION_MODAL: {open, data}} = useSelector(modalReducer);

    const dispatch = useDispatch();

    const toggle = () => dispatch(modalActions.setCloseModal({modal: CONFIRMATION_MODAL}))

    return (
        <Modal isOpen={open}>
            <div className="modal-body">
                <p className="lead text-center">
                    {data?.body ?? ""}
                </p>
            </div>
            <div className="modal-footer d-flex justify-content-center border-0">
                <button className="btn btn-light" type="button" data-bs-dismiss="modal" onClick={toggle}>Cerrar</button>
                <button className="btn btn-primary" type="button" onClick={() => {
                    data?.confirmAction()
                    toggle();
                }}>Aceptar</button>
            </div>
        </Modal>
    );
};
export default ConfirmationModal;