import React, {useState} from 'react';
import {Button, Form} from "reactstrap";
import Inventory from "../../Models/Inventory";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../Redux/Reducers";
import {inventoryActions} from "../../Redux/Inventory/Slice";
import ReactInputMask from "react-input-mask";
import {message} from "antd";


type Props = {
    onClose: () => void
    inventory: Inventory
}


const InventoryDetail = ({inventory, onClose}: Props) => {



    /**
     * 1) useForm como en login
     * 2) onsubmit llamo redux/sagas como en el FilterComponent
     * 3) reducer estado showMain?
     */

    const {conservation_unit_options, object_options, support_options, consultation_frequency_options} = useSelector((state: RootState) => state.inventoryReducer)


    const [readOnly, setReadOnly] = useState(!!inventory._KEY);
    

    const {register, handleSubmit,formState: { errors }} = useForm<Inventory>({
        defaultValues: inventory,
    });

    const {user} = useSelector((state: RootState) => state.authReducer);

    const dispatch = useDispatch();


    const validate = function(date?:string):boolean{


        if(date && date.trim()){
            const parts = date.split("-").map(part => parseInt(part));
            if(parts[1] > 12 || parts[2]>31){
                return true
            }
        }


        return false;
    }

    const onSubmit = handleSubmit((data) => {

        if(validate(data.initial_date)){
            return message.error({content:"Fecha Inicial inválida", duration:3})
        }

        if(validate(data.final_date)){
            return message.error({content:"Fecha Final inválida", duration:3})
        }

        if(!Number.isInteger(Number(data.box_code)) || Number(data.box_code) < 0){
            return message.error({content:"Valor no permitido para 'CODIGO CAJA ARCHIVO'", duration:3})
        }

        if(!Number.isInteger(Number(data.folder_code)) || Number(data.folder_code) < 0){
            return message.error({content:"Valor no permitido para 'CODIGO UNIDAD DOCUMENTAL'", duration:3})
        }



        console.log(data);
        dispatch(inventoryActions.getSaveInventory({inventory: {...data, user: user?._KEY }}));
        onClose();
    });

    const onCancel = () => {

        if(!inventory._KEY){
            return onClose();
        }

        setReadOnly(true);
    };


    console.log(inventory.initial_date)


    return (
        <div className={"d-flex flex-column align-items-center justify-content-center"}>
            <Form onSubmit={onSubmit} className={"row g-3 bg-white p-3 shadow border rounded"} style={{maxWidth: "1200px"}}>


                <div className="col-md-4 mb-4">
                    <label htmlFor="code" className="form-label">CÓDIGO</label>
                    <input id="code" {...register('code', {required: false})} type="text" readOnly={true}
                           className={"form-control"}
                           placeholder={"CÓDIGO"}/>
                </div>
                <div className="col-md-4 mb-4">
                    <label htmlFor="producing_entity" className="form-label">ENTIDAD PRODUCTORA</label>
                    <input id="producing_entity" {...register('producing_entity', {required: false})} type="number"
                           readOnly={true} className={"form-control"}
                           placeholder={"ENTIDAD PRODUCTORA"}/>
                </div>


                <div className="col-md-4 mb-4">
                    <label htmlFor="box_code" className="form-label">CODIGO CAJA ARCHIVO</label>
                    <input id="box_code" {...register('box_code', {required: true, maxLength:5})} type="number" readOnly={readOnly}
                           className={"form-control"}
                           placeholder={"CODIGO CAJA ARCHIVO"}/>
                </div>
                <div className="col-md-4 mb-4">
                    <label>OBJETO</label>
                    <select  id="object" {...register('object', {required: false})}
                             disabled={readOnly}  className={"form-control"}
                             placeholder={"OBJETO"}>
                        <option value="">--Seleccione uno--</option>
                        {
                            object_options
                                .map(opt => {
                                return <option value={opt._KEY} key={opt._KEY} >{opt.label}</option>
                            })
                        }
                    </select>
                </div>
                <div className="col-md-4 mb-4">
                    <label htmlFor="administrative_unit" className="form-label">UNIDAD ADMINISTRATIVA</label>
                    <input id="administrative_unit" {...register('administrative_unit', {required: false})}
                           type="text" readOnly={readOnly} className={"form-control"}
                           placeholder={"UNIDAD ADMINISTRATIVA"}/>
                </div>
                <div className="col-md-4 mb-4">
                    <label htmlFor="production_office" className="form-label">OFICINA PRODUCTORA</label>
                    <input id="production_office" {...register('production_office', {required: false})} type="text"
                           readOnly={readOnly} className={"form-control"}
                           placeholder={"OFICINA PRODUCTORA"}/>
                </div>
                <div className="col-md-4 mb-4">
                    <label htmlFor="serie_subserie" className="form-label">NOMBRE DE LA SERIE/SUBSERIE</label>
                    <input id="serie_subserie" {...register('serie_subserie', {required: false})} type="text"
                           readOnly={readOnly} className={"form-control"}
                           placeholder={"NOMBRE DE LA SERIE/SUBSERIE"}/>
                </div>
                <div className="col-md-4 mb-4">
                    <label htmlFor="folder_title" className="form-label">TITULO DE LA UNIDAD DOCUMENTAL</label>
                    <input id="folder_title" {...register('folder_title', {required: false})} type="text"
                           readOnly={readOnly} className={"form-control"}
                           placeholder={"TITULO DE LA UNIDAD DOCUMENTAL"}/>
                </div>
                <div className="col-md-4 mb-4">
                    <label htmlFor="description" className="form-label">DESCRIPCION COMPLEMENTARIA</label>
                    <textarea id="description" {...register('description', {required: false})} readOnly={readOnly}
                              className={"form-control"}
                              placeholder={"DESCRIPCION COMPLEMENTARIA"}/>
                </div>
                <div className="col-md-4 mb-4">
                    <label htmlFor="initial_date" className="form-label">FECHA INICIAL</label>



                    <ReactInputMask  mask="9999-99-99" id="initial_date" {...register('initial_date', {required: false})} type="text"
                                 disabled={readOnly}  className={"form-control"}
                                 placeholder={"FECHA INICIAL"}/>
                </div>
                <div className="col-md-4 mb-4">

                    <label htmlFor="final_date" className="form-label">FECHA FINAL</label>
                    <ReactInputMask  mask="9999-99-99" id="final_date" {...register('final_date', {required: false})} type="text"
                           disabled={readOnly} className={"form-control"}
                           placeholder={"FECHA FINAL"}/>
                </div>


                <div className="col-md-4 mb-4">
                    <label htmlFor="conservation_unit" className="form-label">UNIDAD DE CONSERVACIÓN</label>

                    <select id="conservation_unit" {...register('conservation_unit', {required: false})}
                            disabled={readOnly} className={"form-control"}
                            placeholder={"UNIDAD DE CONSERVACIÓN"}>
                        <option value="">--Seleccione uno--</option>
                        {
                            conservation_unit_options
                                //.sort((a, b) => a.label > b.label && 1 || -1)
                                .map(opt => {
                                return <option value={opt._KEY} key={opt._KEY} >{opt.label}</option>
                            })
                        }
                    </select>

                </div>
                <div className="col-md-4 mb-4">
                    <label htmlFor="number_of_folios" className="form-label">NUMERO DE FOLIOS</label>
                    <input id="number_of_folios" {...register('number_of_folios', {required: false})} type="number"
                           readOnly={readOnly} className={"form-control"}
                           placeholder={"NUMERO DE FOLIOS"}/>
                    { errors.number_of_folios ?  "máximo 5 dígitos" : ""}
                </div>


                <div className="col-md-4 mb-4">
                    <label htmlFor="support" className="form-label">SOPORTE</label>
                    <select  id="support" {...register('support', {required: false})}
                             disabled={readOnly} className={"form-control"}
                             placeholder={"SOPORTE"}>
                        <option value="">--Seleccione uno--</option>
                        {
                            support_options
                                .map(opt => {
                                    return <option value={opt._KEY}  key={opt._KEY}>{opt.label}</option>
                                })
                        }
                    </select>
                </div>
                <div className="col-md-4 mb-4">
                    <label htmlFor="consultation_frequency" className="form-label">FRECUENCIA DE CONSULTA</label>

                    <select  id="consultation_frequency" {...register('consultation_frequency', {required: false})}
                             disabled={readOnly} className={"form-control"}
                             placeholder={"FRECUENCIA DE CONSULTA"}>
                        <option value="">--Seleccione uno--</option>
                        {
                            consultation_frequency_options
                                .map(opt => {
                                    return <option value={opt._KEY} key={opt._KEY} >{opt.label}</option>
                                })
                        }
                    </select>
                </div>

                <div className="col-md-4 mb-4">
                    <label htmlFor="folder_code" className="form-label">CODIGO UNIDAD DOCUMENTAL</label>
                    <input id="folder_code" {...register('folder_code', {required: true, maxLength:2})} type="number"
                           readOnly={readOnly} className={"form-control"}
                           placeholder={"CODIGO UNIDAD DOCUMENTAL"}/>
                </div>

                <div className="col-md-8 mb-4">
                    <label htmlFor="notes" className="form-label">NOTAS</label>
                    <textarea id="notes" {...register('notes', {required: false})} readOnly={readOnly}
                              className={"form-control"}
                              placeholder={"NOTAS"}/>
                </div>

                <div className={"col-md-12 d-flex justify-content-end"}>
                    <Button color={"primary"} className={"mr-2"} onClick={onClose}>Regresar</Button>
                    <Button hidden={!readOnly} onClick={() => {setReadOnly(false)}} color={"info"} className={"btn mr-2"}>Editar</Button>
                    <Button hidden={readOnly} onClick={onCancel} color={"info"} className={"btn mr-2"}>Cancelar</Button>
                    <Button hidden={readOnly} onClick={onSubmit} color={"info"} className={"btn"}>Guardar</Button>
                </div>
            </Form>
        </div>
    );
};

export default InventoryDetail;
