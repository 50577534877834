import {authActions} from "./Slice";
import {call, put, takeLatest} from "@redux-saga/core/effects";
import {login} from "../../Services/AuthService";
import {sbxSessionService} from "../../Network";
import {GET_LOGIN} from "./Types";
import {message} from "antd";


function* getLoginSaga({payload}:ReturnType<typeof authActions.getLogin>):any{

    try{
        console.log(payload);

        const res:any = yield call(login, payload.email, payload.password);//, payload);

        if (res?.success) {
            sbxSessionService.updateUser({
                token:res.response.token,
                user:res.response.user
            })
            yield put(authActions.setUser(res.response));
        } else {
            message.error({content:"Credenciales Inválidas", duration:3})
            yield put(authActions.setRejected())
            throw Error(res?.error || res?.message || "Ocurrio un error, intente de nuevo.");
        }

    }catch (e){
        console.error(e);
        yield put(authActions.setRejected())
    }

}


export default function* AuthSaga(){
    yield takeLatest(GET_LOGIN, getLoginSaga);
}

