import {combineReducers} from "redux";
import authReducer from './Auth/Slice'
import inventoryReducer from './Inventory/Slice'
import modalReducer from './Modal/Slice'


const RootReducer = combineReducers({
  authReducer,
  inventoryReducer,
  modalReducer
})


export default RootReducer;
export type RootState = ReturnType<typeof RootReducer>
