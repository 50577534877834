import {sbxCoreService} from '../Network';
import Inventory, {InventoryFilters} from '../Models/Inventory';
import dayjs from 'dayjs';


export function getInventoryService(page: number = 1, pageSize: number = 10, filters?: InventoryFilters) {


    let find = sbxCoreService.with("inventory")
      .setPage(page)
      .setPageSize(pageSize);


    if (filters) {
        Object.keys(filters).forEach(key => {
            const val = filters[key as keyof InventoryFilters]
            find = val ? find.andWhereItContains(key, val) : find;
        })
    }


    return find
      .find()
      .then(res => {

          if (res.results) {
              const items: Inventory[] = res.results;
              items.map(item => {
                  item.initial_date = item.initial_date ? dayjs(new Date(item.initial_date)).format("YYYY-MM-DD") : item.initial_date
                  item.final_date = item.final_date ? dayjs(new Date(item.final_date)).format("YYYY-MM-DD") : item.final_date
                  return item;

              });
              return res;
          }

          return res;

      })
}

export function getAllInventoryService({filters}: { filters?: InventoryFilters }) {

    let query = sbxCoreService.with('inventory').fetchModels(['conservation_unit', 'consultation_frequency', 'object', 'support', 'user'])

    if (filters) {
        Object.keys(filters).forEach(key => {
            const val = filters[key as keyof InventoryFilters]
            query = val ? query.andWhereItContains(key, val) : query;
        })
    }

    return query.loadAll().then((res: any) => {
        if (res.success) {
            let items: Inventory[] = res.results;
            items = items.map(item => {
                item.initial_date = item.initial_date ? dayjs(new Date(item.initial_date)).format('YYYY-MM-DD') : item.initial_date;
                item.final_date = item.final_date ? dayjs(new Date(item.final_date)).format('YYYY-MM-DD') : item.final_date;
                Object.keys(res.fetched_results).forEach((key: string) => {
                    const inventoryKey = item[key as keyof Inventory];
                    const obj = res.fetched_results[key][inventoryKey as string]
                    item = {...item, [key]: obj?.label ?? obj?.email ?? ""};
                });

                return item;
            });

            return {success: true, items};
        } else {
            return {success: false, error: res.error};
        }

    })
}


export function saveInventoryService(inventory: Inventory) {


    console.log(inventory);


    if(inventory.initial_date){
        inventory.initial_date = dayjs(inventory.initial_date+" 08:00", 'YYYY-MM-DD hh:mm').toJSON();
    }


    if(inventory.final_date){
        inventory.final_date = dayjs(inventory.final_date+" 08:00", 'YYYY-MM-DD hh:mm').toJSON();
    }


    inventory._META = undefined

    console.log(inventory);


    let q = sbxCoreService.with("inventory");



    if (!!inventory._KEY) {
        console.log("update")
        return q.update([inventory]).then(res => res)
    }

    console.log("create");
    inventory.producing_entity = "MUNICIPIO DE LA CEJA";
    return q.insert([inventory]).then(res => res)


}


export function getConservationUnitOptions() {

    return sbxCoreService.with("conservation_unit")
        .loadAll()
        .then(res => res)
}


export function getObjectOptions() {

    return sbxCoreService.with("object")
        .loadAll()
        .then(res => res)
}


export function getSupportOptions() {

    return sbxCoreService.with("support")
        .loadAll()
        .then(res => res)
}

export function getConsultationFrequencyOptions() {

    return sbxCoreService.with("consultation_frequency")
        .loadAll()
        .then(res => res)
}

export function deleteInventoryService(invKey: string){
    return sbxCoreService.with("inventory").whereWithKeys([invKey]).delete().then((res: any) => res);
}


