import React from 'react';
import InputGroupComponent from '../Shared/InputGroup/InputGroupComponent';
import {Button} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {inventoryActions} from '../../Redux/Inventory/Slice';
import Inventory, {InventoryFilters} from '../../Models/Inventory';
import {RootState} from '../../Redux/Reducers';
import {State} from '../../Types/State';

//@ts-ignore
import ReactExport from "react-data-export";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


type Props = {
    onNew?: () => void,
    inventoryState: InventoryFilters;
    setInventory?: (inv: Inventory) => void;
    handleFilter: (name: string, value: string) => void;
    clearFilters: () => void;
    inventoryData?: Inventory[];
    loadingData?: boolean;
    downloadData: (filterData: InventoryFilters) => void;
}

const headers = [
    {label: "Código", value: "code"},
    {label: "Usuario", value: "user"},
    {label: "Entidad Productora", value: "producing_entity"},
    {label: "Código caja archivo", value: "box_code"},
    {label: "Objeto", value: "object"},
    {label: "Unidad administratiava", value: "administrative_unit"},
    {label: "Oficina productora", value: "production_office"},
    {label: "Nombre de la serie/subserie", value: "serie_subserie"},
    {label: "Titulo de la unidad documental", value: "folder_title"},
    {label: "Descripción complementaria", value: "description"},
    {label: "Fecha inicial", value: "initial_date"},
    {label: "Fecha final", value: "final_date"},
    {label: "Unidad de conservación", value: "conservation_unit"},
    {label: "Numero de folios", value: "number_of_folios"},
    {label: "Soporte", value: "support"},
    {label: "Frecuencia de consulta", value: "consultation_frequency"},
    {label: "Codigo unidad documental", value: "folder_code"},
    {label: "Notas", value: "notes"},
];


const FilterComponent = ({
                             onNew,
                             setInventory,
                             inventoryState,
                             handleFilter,
                             clearFilters,
                             loadingData,
                             inventoryData,
                             downloadData
                         }: Props) => {

    const dispatch = useDispatch();

    const {state: currentState} = useSelector((state: RootState) => state.inventoryReducer)

    const handleClear = () => {
        clearFilters();
        downloadData({})
        dispatch(inventoryActions.getInventory({page: 1, pageSize: 10}))
    }


    const doFilter = () => {
        dispatch(inventoryActions.getInventory({page: 1, pageSize: 10, filters: inventoryState}))
        downloadData(inventoryState);
    }

    const onCreate = () => {
        setInventory && setInventory({
            object: "17bc7a3a-23a6-489e-aee7-33c2cfbc89d8",
            conservation_unit: "2c688e94-764b-4de8-a14b-2ddc15f6b728",
            support: "bfe169a9-12df-451a-a04b-424efc7a5d5f",
            consultation_frequency: "44c21fa1-ba0e-4dc1-8dae-9d84246a4320",
            number_of_folios: 0
        } as Inventory);
        onNew && onNew();
    }

    return (
        <div className={"d-flex flex-column"}>
            <div className={"d-flex flex-wrap"} style={{columnGap: '25px', paddingBottom: '25px'}}>
                <Button color={"primary"} outline onClick={onCreate}>Nuevo Registro</Button>
            </div>
            <div className={"d-flex flex-wrap"} style={{columnGap: '25px'}}>
                <InputGroupComponent value={inventoryState.serie_subserie ?? ""} label={"NOMBRE DE LA SERIE/SUBSERIE"}
                                     onChange={(value) => handleFilter("serie_subserie", value)}/>
                <InputGroupComponent value={inventoryState.folder_title ?? ""} label={"TITULO DE LA UNIDAD DOCUMENTAL"}
                                     onChange={(value) => handleFilter("folder_title", value)}/>
                <InputGroupComponent value={inventoryState.description ?? ""} label={"DESCRIPCION COMPLEMENTARIA"}
                                     onChange={(value) => handleFilter("description", value)}/>
                <InputGroupComponent value={inventoryState.box_code ?? ""} label={"CAJA"}
                                     onChange={(value) => handleFilter("box_code", value)}/>
                {/*<InputGroupComponent value={inventoryState.notes ?? ""} label={"NOTAS"}*/}
                {/*                     onChange={(value) => handleFilter("notes", value)}/>*/}
            </div>
            <div className={"d-flex align-items-center mt-2"}>
                <Button color={"primary"} className={"mr-2"} onClick={doFilter}
                        disabled={State.PENDING === currentState}>Filtrar</Button>
                <Button color={"info"} className={"btn mr-2"} onClick={handleClear}
                        disabled={State.PENDING === currentState}>Limpiar</Button>


                <ExcelFile element={<Button color={"secondary"} disabled={loadingData}
                                            className={"mr-2"}>{loadingData &&
                <FontAwesomeIcon icon={faSpinner} pulse/>}Exportar</Button>}>
                    <ExcelSheet data={inventoryData} name="Inventory">
                        {headers.map((header, index) => (
                            <ExcelColumn label={header.label} value={header.value} key={index}/>
                        ))}
                    </ExcelSheet>
                </ExcelFile>

            </div>
        </div>
    );
};

export default FilterComponent;
