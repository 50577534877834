import User from "../../Models/User";
import {State} from "../../Types/State";

export const GET_LOGIN = "auth/getLogin";
export const LOGOUT = "auth/logout";


export interface InitialState {
  token: string | null,
  isLogged: boolean,
  user:User | null,
  state: State
}

export interface AuthState {
  state: string,
  user: User,
  logged: boolean,
  token: string | null,
}



export interface LoginBody {
  login: string,
  password: string
}

export interface AuthState {
  state: string,
  user: User,
  logged: boolean,
  token: string | null,
}

export interface RecoverPasswordParams {
  code: string,
  password: string,
  repeatPassword?: string,
  user_id: string,
}
