import {all} from "@redux-saga/core/effects"
import AuthSaga from "./Auth/Saga";
import InventorySaga from "./Inventory/Saga";


export default function* rootSaga() {
    yield all([
        AuthSaga(),
        InventorySaga(),
    ])
};
