import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {State} from "../../Types/State";
import {InitialState} from "./Types";
import {AuthData} from "../../Models/AuthData";



const {IDLE, RESOLVED, REJECTED, PENDING} = State;

const token = localStorage.getItem('token');

const initialState: InitialState = {
    token: null,
    isLogged: !!token,
    user:null,
    state: IDLE
};
const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        getLogin(state, {payload}:PayloadAction<{
            email: string;
            password: string;
        }>) {
            state.state = PENDING;
        },
        setUser(state, {payload}: PayloadAction<AuthData>) {
            state.token = payload.token;
            state.isLogged = true
            state.state = RESOLVED;
            state.user = payload.user;
        },
        logout(state){
            return initialState;
        },
        setResolved(state){
            state.state = RESOLVED
        },
        setRejected(state){
            state.state = REJECTED
        }

    }
});
export const authActions = slice.actions;
export default slice.reducer;
