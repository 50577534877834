import React from 'react';

import './App.scss';
import LoginComponent from "./Components/AuthComponent/LoginComponent/LoginComponent";
import MainComponent from "./Components/MainComponent/MainComponent";
import {useSelector} from "react-redux";
import {RootState} from "./Redux/Reducers";
import {sbxSessionService} from "./Network";


function App() {

    const {isLogged} = useSelector((state: RootState) => state.authReducer)


    const getToken = () => {

        const token = localStorage.getItem("token")

        if (token) {
            sbxSessionService.updateToken(token);
            return true;
        } else if (isLogged) {
            return true;
        }

        return false;
    }


    return <>
        {
            getToken() ? <MainComponent/> : <LoginComponent/>
        }
    </>;
}

export default App;
