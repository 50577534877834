import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Inventory from "../../Models/Inventory";
import {Pagination} from "antd";
import {inventoryActions} from "../../Redux/Inventory/Slice";
import {Button} from "reactstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileAlt, faTrash} from "@fortawesome/free-solid-svg-icons";
import {UserRole} from "../../Types/Rol";
import {authReducer, inventoryReducer} from "../../Redux/Selectors";
import {modalActions} from "../../Redux/Modal/Slice";
import {CONFIRMATION_MODAL} from "../../Redux/Modal/Types";


type Props = {
    onNew?: () => void
    setInventory?: (inv:Inventory) => void
    filters: any;
}

const TableComponent = ({onNew, setInventory, filters}: Props) => {

    const {inventories, rowCount} = useSelector(inventoryReducer)
    const {user} = useSelector(authReducer);
    const dispatch = useDispatch();

    const onPageChange = (page: number, pageSize?: number) => {
        dispatch(inventoryActions.getInventory({page: page, pageSize: pageSize ?? 1, filters}))
    }

    const onEdit = (item: Inventory) => {
        setInventory && setInventory(item);
        onNew && onNew();
    }

    const deleteInventory = (inventory: Inventory) => {
        const confirmAction = () => dispatch(inventoryActions.deleteInventory({inventory}))
        const body = "¿Desea eliminar el registro seleccionado?"
        dispatch(modalActions.setOpenModal({modal: CONFIRMATION_MODAL, data: {confirmAction, body}}))
    }

    return (
        <div className={"mt-5"}>


            <table className="table table-bordered table-striped table-responsive-stack">
                <thead>
                <tr>
                    <th scope="col">NOMBRE DE LA SERIE/SUBSERIE</th>
                    <th scope="col">TITULO DE LA UNIDAD DOCUMENTAL</th>
                    <th scope="col">ACCIONES</th>
                </tr>
                </thead>
                <tbody>
                {
                    inventories?.map((item: any) => (
                        <tr key={item._KEY}>
                            <td>{item.serie_subserie}</td>
                            <td>{item.folder_title}</td>
                            <td className="d-flex justify-content-around">
                                <Button onClick={() => onEdit(item)}>
                                    <FontAwesomeIcon color={"info"} icon={faFileAlt}/>
                                </Button>
                                {user?.role === UserRole.ADMIN && <Button onClick={() => deleteInventory(item)} color={"danger"}>
                                    <FontAwesomeIcon icon={faTrash}/>
                                </Button>}
                            </td>
                        </tr>
                    ))
                }

                </tbody>
            </table>
            <div className="d-flex justify-content-center mb-4">
                {rowCount > 0 && <Pagination defaultCurrent={1}  total={rowCount} onChange={onPageChange}/>}
            </div>

        </div>
    );
};

export default TableComponent;
