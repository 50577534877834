import React, {useEffect, useReducer, useState} from 'react';
import HeaderComponent from "../Shared/HeaderComponent/HeaderComponent";
import FilterComponent from "../FilterComponent/FilterComponent";
import TableComponent from "../TableComponent/TableComponent";
import {useDispatch} from "react-redux";
import {inventoryActions} from "../../Redux/Inventory/Slice";
import InventoryDetail from "../InventoryDetail/InventoryDetail";
import Inventory, {InventoryFilters} from "../../Models/Inventory";
import {Response} from "../../Types/Response";
import {getAllInventoryService} from "../../Services/InventoryService";
import {message} from "antd";
import {State} from "../../Types/State";


enum Types {
    ADD_FILTER = "FILTER",
    CLEAR = "CLEAR"
}

const initialState: InventoryFilters = {};

function reducer(state: InventoryFilters, {payload, type}: { payload?: any, type: Types }) {
    switch (type) {
        case Types.ADD_FILTER:
            return {
                ...state,
                [payload.name]: payload.value
            };
        case Types.CLEAR:
            return initialState;
        default:
            throw new Error();
    }
}

const MainComponent = () => {

    const [localState, dispatchLocal] = useReducer(reducer, initialState);
    const dispatch = useDispatch();
    const [allInventory, setAllInventory] = useState<Inventory[]>([])
    const [loadingData, setLoadingData] = useState(State.IDLE);

    const downloadData = async (filterData: InventoryFilters) => {
        setLoadingData(State.PENDING);
        const filters = Object.keys(filterData).length > 0 ? filterData : {}
        const response: Response<Inventory> = await getAllInventoryService({filters});
        if (response?.success && response?.items) {
            setAllInventory(response.items);
            setLoadingData(State.RESOLVED);
        } else {
            setLoadingData(State.REJECTED);
            return message.error({content: 'Ocurrió un error al descargar los inventarios', duration: 3});
        }
    };

    React.useEffect(() => {
        downloadData({});
    }, [])



    const handleFilter = (name: string, value: string) => {
        dispatchLocal({type: Types.ADD_FILTER, payload: {name, value}})
    }

    const clearFilters = () => dispatchLocal({type: Types.CLEAR})

    useEffect(() => {
        dispatch(inventoryActions.getInventory({page: 1, pageSize: 10}))
        dispatch(inventoryActions.getConservationUnitOptions())
        dispatch(inventoryActions.getObjectOptions())
        dispatch(inventoryActions.getSupportOptions())
        dispatch(inventoryActions.getConsultationFrequencyOptions())
    }, [dispatch])


    const [showMain, setShowMain] = useState(true)

    const [inventory, setInventory] = useState<Inventory>(({} as Inventory))


    return <>
        <HeaderComponent/>


        {
            showMain ?
                <div className={"container d-flex flex-column mt-3"}>
                    <FilterComponent inventoryData={allInventory} loadingData={loadingData === State.PENDING} clearFilters={clearFilters}
                                     handleFilter={handleFilter} inventoryState={localState} downloadData={downloadData}
                                     onNew={() => setShowMain(false)} setInventory={setInventory}/>
                    <TableComponent filters={localState} onNew={() => setShowMain(false)} setInventory={setInventory}/>
                </div>
                :
                <InventoryDetail inventory={inventory} onClose={() => setShowMain(true)}/>
        }

    </>
};

export default MainComponent;
