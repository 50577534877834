import {compose, createStore, applyMiddleware} from "redux";
import RootReducer from './Reducers'
import createSagaMiddleware from 'redux-saga';
import  rootSaga from "./Sagas";


const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();




const store = createStore(RootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)))


sagaMiddleware.run(rootSaga);



export default store;
