import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {State} from "../../Types/State";
import {InitialState} from "./Type";
import Inventory, {InventoryFilters} from "../../Models/Inventory";
import Option from "../../Models/Option";


const {IDLE, RESOLVED, REJECTED, PENDING} = State;

const initialState: InitialState = {
    inventories: [],
    conservation_unit_options:[],
    object_options:[],
    support_options:[],
    consultation_frequency_options:[],
    rowCount: 0,
    filters: {},
    state: IDLE
};
const slice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {
        getSaveInventory(state, {payload}:PayloadAction<{
            inventory: Inventory
        }>) {
            state.state = PENDING
        },
        getConsultationFrequencyOptions(state) {
            state.state = PENDING
        },
        setConsultationFrequencyOptions(state, {payload}: PayloadAction<{
            consultation_frequency_options: Option[];
        }>) {
            state.state = RESOLVED;
            state.consultation_frequency_options = payload.consultation_frequency_options;
        },
        getSupportOptions(state) {
            state.state = PENDING
        },
        setSupportOptions(state, {payload}: PayloadAction<{
            support_options: Option[];
        }>) {
            state.state = RESOLVED;
            state.support_options = payload.support_options;
        },
        getObjectOptions(state) {
            state.state = PENDING
        },
        setObjectOptions(state, {payload}: PayloadAction<{
            object_options: Option[];
        }>) {
            state.state = RESOLVED;
            state.object_options = payload.object_options;
        },
        getConservationUnitOptions(state) {
            state.state = PENDING
        },
        setConservationUnitOptions(state, {payload}: PayloadAction<{
            conservation_unit_options: Option[];
        }>) {
            state.state = RESOLVED;
            state.conservation_unit_options = payload.conservation_unit_options;
        },
        getInventory(state, {payload}:PayloadAction<{
            page: number;
            pageSize: number;
            filters?: InventoryFilters
            }>) {
            state.state = PENDING;
            state.filters = payload.filters ? payload.filters : {};
        },
        setInventory(state, {payload}: PayloadAction<{
            inventory: Inventory[];
            rowCount: number;
        }>) {
            state.rowCount = payload.rowCount;
            state.state = RESOLVED;
            state.inventories = payload.inventory;
        },
        deleteInventory(state, {payload}: PayloadAction<{inventory: Inventory}>){
          state.state = PENDING
        },
        setResolved(state) {
            state.state = RESOLVED
        },
        setRejected(state) {
            state.state = REJECTED
        }
    }
});
export const inventoryActions = slice.actions;
export default slice.reducer;
