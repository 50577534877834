import {State} from "../../Types/State";
import Inventory, {InventoryFilters} from "../../Models/Inventory";
import Option from "../../Models/Option";


export const GET_INVENTORY = "inventory/getInventory";
export const GET_SAVE_INVENTORY = "inventory/getSaveInventory";
export const GET_CONSERVATION_UNIT_OPTIONS = "inventory/getConservationUnitOptions";
export const GET_OBJECT_OPTIONS = "inventory/getObjectOptions";
export const GET_SUPPORT_OPTIONS = "inventory/getSupportOptions";
export const GET_CONSULTATION_FREQUENCY_OPTIONS = "inventory/getConservationUnitOptions";
export const DELETE_INVENTORY = "inventory/deleteInventory";




export interface InitialState {
    inventories: Inventory[],
    rowCount: number,
    filters: InventoryFilters
    conservation_unit_options: Option[],
    object_options:Option[],
    support_options:Option[],
    consultation_frequency_options:Option[],
    state: State
}




