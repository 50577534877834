import React from 'react';
import {Button, Form} from 'reactstrap';
import "./LoginComponent.scss"
import ozaLogo from "../../../Assets/Images/ozaecm.jpeg"
import poweredLogo from "../../../Assets/Images/powered_by_sbx.png"
import {useForm} from 'react-hook-form';
import LoginModel from "./LoginModel";
import {useDispatch} from "react-redux";
import {authActions} from "../../../Redux/Auth/Slice";


const LoginComponent = () => {

    const {register, handleSubmit} = useForm<LoginModel>();

    const dispatch = useDispatch();

    const onSubmit = handleSubmit((data, e) => {
        dispatch(authActions.getLogin({email:data.email, password:data.password}))
    });


    return (
        <div className={"login-view d-flex flex-column align-items-center justify-content-center bg-danger"}>
            <Form onSubmit={onSubmit} className={"d-flex flex-column bg-white p-5 shadow border rounded"}>
                <div className={"d-flex justify-content-center"}>
                    <img src={ozaLogo} style={{width: "auto", height: "80px"}} className={"img-fluid"}
                         alt={"OZA ECM By SOCOBOX"}/>
                </div>
                <input id="email" {...register('email', {required: true})} type="email" className={"form-control mt-3"}
                       placeholder={"Correo electrónico"}/>
                <input id="password" {...register('password', {required: true})} type="password"
                       className={"form-control mt-3"}
                       placeholder={"Clave"}/>
                <Button color="primary mt-3">Login</Button>
                <div className={"row mt-3"}>
                    <div className={"col md-12 justify-content-center"}>
                        <a className="powered" href="https://socobox.co" target="_blank" rel="noopener noreferrer">
                            <h6>Powered by: <img style={{maxWidth: "60px"}} src={poweredLogo}
                                                 className={"img-fluid img-thumbnail"}
                                                 alt={"SBX Cloud Platform by SOCOBOX SAS"}/></h6>
                        </a>
                    </div>
                </div>
            </Form>

        </div>

    );
};

export default LoginComponent;
