import React from "react";
import {useSelector} from "react-redux";
import ConfirmationModal from "./Modal/ConfirmationModal/ConfirmationModal";
import {modalReducer} from "../../Redux/Selectors";


const ModalWrapperContainer = () => {
    const {
      CONFIRMATION_MODAL
    } = useSelector(modalReducer);

    return (
        <>
            {CONFIRMATION_MODAL.open && <ConfirmationModal/>}
        </>
    );
};

export default ModalWrapperContainer;
