
import {call, put, takeLatest} from "@redux-saga/core/effects";
import { message } from 'antd';
import {
    deleteInventoryService,
    getConservationUnitOptions, getConsultationFrequencyOptions,
    getInventoryService,
    getObjectOptions,
    getSupportOptions, saveInventoryService
} from "../../Services/InventoryService";
import {
    DELETE_INVENTORY,
    GET_CONSERVATION_UNIT_OPTIONS,
    GET_CONSULTATION_FREQUENCY_OPTIONS,
    GET_INVENTORY,
    GET_OBJECT_OPTIONS, GET_SAVE_INVENTORY,
    GET_SUPPORT_OPTIONS
} from "./Type";
import {inventoryActions} from "./Slice";


function* getInventorySaga({payload}:ReturnType<typeof inventoryActions.getInventory>):any{

    try{
        const res:any = yield call(getInventoryService, payload.page, payload.pageSize, payload.filters);//, payload);

        if (res.success && res.results) {
            yield put(inventoryActions.setInventory({inventory:res.results, rowCount:res.row_count}));
        } else {
            yield put(inventoryActions.setRejected())
            throw Error(res?.error || res?.message || "Ocurrio un error, intente de nuevo.");
        }

    }catch (e){
        console.error(e);
        yield put(inventoryActions.setRejected())
    }

}

function* getSaveInventorySaga({payload}:ReturnType<typeof inventoryActions.getSaveInventory>):any{

    try{
        const k = new Date().getMilliseconds()
        message.loading({content:"Guardando...", key:k})
        const res:any = yield call(saveInventoryService, payload.inventory);//, payload);

        if (res.success) {
            message.success({content:"Guardado Exitoso", key:k,duration:3})
            yield put(inventoryActions.getInventory({page:1, pageSize:10}))
        } else {
            yield put(inventoryActions.setRejected())
            throw Error(res?.error || res?.message || "Ocurrio un error, intente de nuevo.");
        }

    }catch (e){
        console.error(e);
        yield put(inventoryActions.setRejected())
    }

}

function* getConservationUnitOptionsSaga({payload}:ReturnType<typeof inventoryActions.getConservationUnitOptions>):any{

    try{
        const res:any = yield call(getConservationUnitOptions);

        if (res.success && res.results) {

            yield put(inventoryActions.setConservationUnitOptions({conservation_unit_options:res.results}));
        } else {
            yield put(inventoryActions.setRejected())
            throw Error(res?.error || res?.message || "Ocurrio un error, intente de nuevo.");
        }

    }catch (e){
        console.error(e);
        yield put(inventoryActions.setRejected())
    }

}


function* getObjectOptionsSaga({payload}:ReturnType<typeof inventoryActions.getObjectOptions>):any{

    try{
        const res:any = yield call(getObjectOptions);

        if (res.success && res.results) {

            yield put(inventoryActions.setObjectOptions({object_options:res.results}));
        } else {
            yield put(inventoryActions.setRejected())
            throw Error(res?.error || res?.message || "Ocurrio un error, intente de nuevo.");
        }

    }catch (e){
        console.error(e);
        yield put(inventoryActions.setRejected())
    }

}

function* getSupportOptionsSaga({payload}:ReturnType<typeof inventoryActions.getSupportOptions>):any{

    try{
        const res:any = yield call(getSupportOptions);

        if (res.success && res.results) {

            yield put(inventoryActions.setSupportOptions({support_options:res.results}));
        } else {
            yield put(inventoryActions.setRejected())
            throw Error(res?.error || res?.message || "Ocurrio un error, intente de nuevo.");
        }

    }catch (e){
        console.error(e);
        yield put(inventoryActions.setRejected())
    }

}

function* getConsultationFrequencyOptionsSaga({payload}:ReturnType<typeof inventoryActions.getConsultationFrequencyOptions>):any{

    try{
        const res:any = yield call(getConsultationFrequencyOptions);

        if (res.success && res.results) {

            yield put(inventoryActions.setConsultationFrequencyOptions({consultation_frequency_options:res.results}));
        } else {
            yield put(inventoryActions.setRejected())
            throw Error(res?.error || res?.message || "Ocurrio un error, intente de nuevo.");
        }

    }catch (e){
        console.error(e);
        yield put(inventoryActions.setRejected())
    }

}

function* deleteInventorySaga({payload}: ReturnType<typeof inventoryActions.deleteInventory>): any {
    try {
        const k = new Date().getMilliseconds()
        message.loading({content:"Eliminando...", key:k})
        const response = yield call(deleteInventoryService, payload.inventory._KEY);

        if (response?.success) {
            message.success({content:"Registro eliminado!", key: k,duration:3})
            yield getInventorySaga({type: GET_INVENTORY, payload: {pageSize: 10, page: 1}})
        } else {
            yield put(inventoryActions.setRejected())
            throw Error(response?.error || response?.message || "Ocurrio un error, intente de nuevo.");
        }
    } catch (e) {
        console.error(e)
        yield put(inventoryActions.setRejected())
    }
}

export default function* InventorySaga(){
    yield takeLatest(GET_INVENTORY, getInventorySaga);
    yield takeLatest(GET_SAVE_INVENTORY, getSaveInventorySaga);
    yield takeLatest(GET_CONSERVATION_UNIT_OPTIONS, getConservationUnitOptionsSaga);
    yield takeLatest(GET_OBJECT_OPTIONS, getObjectOptionsSaga);
    yield takeLatest(GET_SUPPORT_OPTIONS, getSupportOptionsSaga);
    yield takeLatest(GET_CONSULTATION_FREQUENCY_OPTIONS, getConsultationFrequencyOptionsSaga);
    yield takeLatest(DELETE_INVENTORY, deleteInventorySaga)
}
