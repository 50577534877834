import { SbxCoreService, SbxSessionService } from "sbx-axios";
import env from "../Environment";
import { initialize } from "sbx-react-auth-hoc";
const sbxCoreService = new SbxCoreService();
const sbxSessionService = new SbxSessionService(sbxCoreService);

sbxSessionService.initialize(
  env.DOMAIN,
  env.APP_KEY
  //env.BASE_URL
);

const interceptor = (res: any) => {
  if (!res.success && res.error) {
    res.message = res.error;
    delete res.error;
  }
  return res;
}

sbxCoreService.httpClient.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
  }
);

sbxCoreService.setResponseInterceptor(interceptor, () => {});

const runCS = function(key: string, params: any, test?: boolean) {
  return sbxCoreService.run(key, params, test !== undefined ? test : env.TEST_MODE).then(interceptor);
};

initialize(
  sbxCoreService,
  sbxSessionService,
  env.CLOUD_SCRIPTS.VALIDATE
);

export { sbxSessionService, sbxCoreService, runCS };
