import Env from "./Models/Env";

const DEV: Env = {
  APP_KEY: process.env.REACT_APP_APP_KEY || "",
  BASE_URL: "https://sbxcloud.com/api",
  VERSION_APP: "1.0.0-20210326",
  CLOUD_SCRIPTS: {
    LOGIN: process.env.REACT_APP_LOGIN || "",
    VALIDATE: process.env.REACT_APP_VALIDATE || "",
    UPSERT_INVENTORY: process.env.REACT_APP_UPSERT_INVENTORY || "",
  },
  FOLDERS: {
    IMAGES: "67cd2992-10dc-4da2-8416-300c396be5a5"
  },
  DOMAIN: parseInt(process.env.REACT_APP_DOMAIN || "0"),
  PUBLIC_IMAGES_PATH: "https://sbxcloud.com/www/ozaecmprod/images/",
  TEST_MODE: false
};


let env: Env = DEV;

export default env;
