import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CONFIRMATION_MODAL, Modal, ModalState} from "./Types";

const initialState: ModalState = {
    [CONFIRMATION_MODAL]: {
        open: false
    },
}

const slice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        setOpenModal(state: any, action: PayloadAction<Modal>) {
            if (action.payload.modal) {
                state[action.payload.modal].open = true;
                state[action.payload.modal].data =
                    action.payload.data !== undefined ? action.payload.data : null;
                state[action.payload.modal].result = null;
            }
        },
        setCloseModal(state: any, action: PayloadAction<Modal>) {
            if (action.payload.modal) {
                state[action.payload.modal].open = false;
                state[action.payload.modal].data = null;
                state[action.payload.modal].result =
                    action.payload.data !== undefined ? action.payload.data : null;
            }
        },
        setCleanAllModals() {
            return initialState;
        },
    }
});

export const modalActions = slice.actions;

export default slice.reducer;