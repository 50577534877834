import React, {useState} from 'react';
import {Collapse, Nav, Navbar, NavbarBrand, NavbarText, NavbarToggler} from 'reactstrap';
import {useDispatch} from "react-redux";
import env from '../../../Environment';
import {authActions} from "../../../Redux/Auth/Slice";
import {sbxSessionService} from "../../../Network";

const HeaderComponent = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    const dispatch = useDispatch();

    const doLogout = () => {
        dispatch(authActions.logout())
        localStorage.clear();
        sbxSessionService.logout();
        window.location.reload();
    };

    return (
        <div>
            <Navbar color="light" light expand="md">
                <NavbarBrand href="#home">
                    <img src={"https://sbxcloud.com/www/ozaecmdev/"+env.DOMAIN+".png"}
                         style={{height:"60px"}}
                         className="d-inline-block align-top" alt={"Municipio de La Ceja"}/>
                </NavbarBrand>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="mr-auto" navbar>
                    </Nav>
                    <NavbarText onClick={doLogout} style={{cursor:"pointer"}}>Logout</NavbarText>
                </Collapse>
            </Navbar>
        </div>
    );
};

export default HeaderComponent;
